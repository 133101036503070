import { ILoadDatasourcesAction } from '../../core/actions/loadDatasourcesAction'
import { IRemoveDatasourcesAction } from '../../core/actions/removeDatasourceAction'
import IDatasourceListViewHandlers from '../../core/views/IDatasourcesListViewHandlers'

export const DatasourcesListPresenter = (
  viewHandlers: IDatasourceListViewHandlers,
  loadDatasourcesAction: ILoadDatasourcesAction,
  removeDatasourceAction: IRemoveDatasourcesAction
) => {
  return {
    present() {
      console.log('Lista de origenes renderizada')
    },
    
    loadDatasources(page_number=1) {
      const loadDatasourcesRequest = loadDatasourcesAction.execute(page_number)
      loadDatasourcesRequest.then((res) => {
        viewHandlers.loadDatasourcesOk(res)
      })
    },

    removeDatasource(id:string) {
      const removeDatasourceRequest = removeDatasourceAction.execute(id)
      removeDatasourceRequest.then(res => {
        viewHandlers.removeDatasourceOk(res)
      })
    }
  }
}