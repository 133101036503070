import React, { useEffect, useState, ReactNode } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Form, Input, InputSelect, InputMultiSelect, SqlEditor, getValues } from '../../../components/libraries/Form'
import { Portal } from 'react-portal'
import { v4 as uuidv4 } from 'uuid';
import { MdDelete } from "react-icons/md";
import Table from '../../../components/libraries/Table'
import TableFromQuery from '../../../components/libraries/TableFromQuery'
import Alert from '../../../components/libraries/Alert'
import useModal from '../../../hooks/useModal'
import Colapsable from '../../../components/libraries/Colapsable'
import { getResourcesAddOrEditPresenter } from '../infrastructure/presentation/presenterProvider'
import IResourcesAddOrEditViewHandlers from '../core/views/IResourcesAddOrEditViewHandlers'
import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { createHashMap } from '../../../utils';
interface CompilerFreePassInterface {
  [key: string]: any
}
const formaters: CompilerFreePassInterface = {
  singleParams: ({ data, symbol }: CompilerFreePassInterface) => `${symbol} ${data}`,
  dualParams: ({ field, data, symbol }: CompilerFreePassInterface) => `${field} ${symbol} ${data}`,
  tripleParams: ({ field, data: [data1, data2], symbol }: CompilerFreePassInterface) => `${field} ${symbol} ${data1} AND ${data2}`
}

// Recibimos dos parametros por defecto 'request' es una plantilla para hacer peticiones
const AddOrEditresources = () => {
  // Definimos navigate para poder redigir a otro componente
  const navigate = useNavigate()
  // Toggles
  const [resetMainSpecialFields, setResetMainSpecialFields] = useState<boolean>(false)
  const [resetSecondarySelects, setResetSecondarySelects] = useState<boolean>(false)
  const [advancedQuery, setAdvancedQuery] = useState<boolean>(false)
  const [skipPagination, setSkipPagination] = useState<boolean>(false)
  const [isQueryTested, setIsQueryTested] = useState<boolean>(false)
  const [advancedQueryAlertConfirmed, setAdvancedQueryAlertConfirmed] = useState<boolean>(false)

  // Objeto a editar 
  const [resourceToEdit, setResourceToEdit] = useState<CompilerFreePassInterface>({})

  // Athena seleccionado
  const[disablePaginationOnClient, setDisablePaginationOnClient] = useState<boolean>(false)
  // Resultados 
  const [result, setResult] = useState('')

  // Ids de los filtros que hay que eliminar
  const [filtersIdsForDelete, setFiltersIdsForDelete] = useState<string[]>([])
  const [filterMethods, setFilterMethods] = useState<CompilerFreePassInterface>({})
  const [filterMethodsOptions, setFilterMethodsOptions] = useState<CompilerFreePassInterface[]>([])
  const [filters, setFilters] = useState<CompilerFreePassInterface[]>([])

  // Opciones para los selects
  const [dataSourceOptions, setDataSourceOptions] = useState<CompilerFreePassInterface[]>([])
  const [dataBaseOptions, setDataBaseOptions] = useState<CompilerFreePassInterface[]>([])
  const [tableOptions, setTableOptions] = useState<CompilerFreePassInterface[]>([])
  const [columnsOptions, setColumnOptions] = useState<CompilerFreePassInterface[]>([])
  const [columnsTypes, setColumnsTypes] = useState<CompilerFreePassInterface>({})

  // Tab manager
  const [tabIndex, setTabIndex] = useState(0)

  // Parametros
  const { id = '' } = useParams()
  // Modal de filtros
  const [FiltersModal, openFiltersModal, closeFiltersModal] = useModal()
  const [filtersPreview, setFiltersPreview] = useState<ReactNode[] | null>(null)

  // Cuando cambia el origen seleccionado se traen las bases de datos
  const handleDataSourceChange = ({ value }: HTMLInputElement | CompilerFreePassInterface) => {
    setDataBaseOptions([])
    setTableOptions([])
    setColumnOptions([])
    presenter.loadDataBases({ datasource_id: value })
    const selectedDatasource = dataSourceOptions.find(option => option.value === value)?.object
    disablePagination(selectedDatasource?.client)
  }
  const disablePagination = (selectedDatasource:string) => {
    const clients = ['Amazon Athena','Oracle']
    setDisablePaginationOnClient(clients.includes(selectedDatasource))
  }
  // Cuando cambia la base de datos seleccionada se traen las tablas
  const handleDataBaseChange = ({ value }: HTMLInputElement | CompilerFreePassInterface, { datasource_id }: CompilerFreePassInterface) => {
    setTableOptions([])
    setColumnOptions([])
    presenter.loadTables({
      datasource_id,
      database: value
    })
  }

  // Cuando cambia la tabla seleccionada se traen las columnas y sus tipos de datos
  const handleTableChange = ({ value }: HTMLInputElement | CompilerFreePassInterface, { datasource_id, database }: CompilerFreePassInterface) => {
    setColumnOptions([])
    presenter.loadColumns({
      datasource_id,
      database,
      table: value
    })
  }

  const viewHandlers: IResourcesAddOrEditViewHandlers =
  {
    loadFilterMethodsOk({ methods, methodsOptions }) {
      setFilterMethods(methods)
      setFilterMethodsOptions(methodsOptions)
    },
    loadResourceToEditOk(resource) {
      const { advanced_query, datasource, database, table, skip_pagination= false } = resource
      setAdvancedQuery(advanced_query)
      setSkipPagination(skip_pagination)
      setResourceToEdit(resource)
      handleDataSourceChange({ value: datasource })
      handleDataBaseChange({ value: database }, { datasource_id: datasource })
      if (!advanced_query) {
        handleTableChange({ value: table }, { datasource_id: datasource, database: database })
      }
    },
    loadResourceFiltersOk(filters) {
      setFilters(filters)
    },
    loadDataSourcesOk(options) {
      setDataSourceOptions(options)
    },
    loadDataBasesOk(options) {
      setDataBaseOptions(options)
    },
    loadTablesOk(options) {
      setTableOptions(options)
    },
    loadColumnsOk({ types, options }) {
      setColumnOptions(options)
      setColumnsTypes(types)
    },
    saveResourceOk(msg, form) {
      form.reset()
      resetSpecialFields()
      setResult('')
      setIsQueryTested(false)
      navigate('/recursos')
      Alert.fire({ title: "¡Perfecto!", text: msg, icon: 'success' });
    },
    testQueryOk(result) {
      setResult(JSON.stringify(result, null, 2))
      setIsQueryTested(true)
      closeFiltersModal()
      setFiltersPreview(null)
    },
  }


  const presenter = getResourcesAddOrEditPresenter(viewHandlers)

  const tabs = [
    {
      label: 'Table',
      component: <pre className='bg-main border h-56 max-h-96 p-3 border-bg overflow-x-auto rounded-lg mt-2 w-full'><TableFromQuery noDataMessage='Esperando resultados' result={result} /></pre>
    },
    {
      label: 'JSON',
      component: <pre className='bg-bg h-56 max-h-96 overflow-y-auto p-3 rounded-lg  mt-2'>{result || 'Esperando resultados'}</pre>
    },
  ]

  // Formulario principal
  const resetSpecialFields = () => {
    setDataBaseOptions([])
    setTableOptions([])
    setColumnOptions([])
    setResetMainSpecialFields(!resetMainSpecialFields)
  }

  const mainSubmit = ({ datasource_id: datasource, limit1, limit2, ...data }: CompilerFreePassInterface, form: HTMLFormElement) => {
    const query = advancedQuery ? data.queryString : `SELECT ${data.columns || '*'} FROM ${data.table}`
    const limit = disablePaginationOnClient || skipPagination ? (advancedQuery ? limit2 : limit1) || 0 : (advancedQuery ? limit2 : limit1) || 10
    if (!data.columns?.length || advancedQuery) data.columns = []
    if (advancedQuery) {
      data.table = ''
    }
    const values = {
      ...data,
      query,
      datasource,
      limit,
      filters,
      filtersIdsForDelete,
      owner: localStorage.getItem('user_id')
    }
    console.log(values)
    presenter.saveResource(values, id, form)

  }
  const testQuery = ({ columns, table, limit1, limit2, datasource_id, database, queryString, query_filters = '', skip_pagination }: CompilerFreePassInterface, form: HTMLFormElement) => {
    if (filters.length && form && !advancedQuery) {
      openFiltersModal()
      return
    }
    setResult('')
    if (!advancedQuery) queryString = `SELECT ${columns || '*'} FROM ${table} ${query_filters}`
    const limit = disablePaginationOnClient || skipPagination ? (advancedQuery ? limit2 : limit1) || 0 : (advancedQuery ? limit2 : limit1) || 10

    presenter.testQuery({
      datasource_id,
      database,
      limit,
      queryString,
      skip_pagination
    })
  }
  const functions: CompilerFreePassInterface = createHashMap({
    'main-submit': mainSubmit,
    // Funcion para probar la consulta
    'test-query': testQuery
  },mainSubmit)
  
  const handleSubmit = (values: CompilerFreePassInterface, form: HTMLFormElement) => {
    if (document.activeElement) functions[document.activeElement.id](values, form)
  }

  // *Formulario de filtros (Portaled)*

  const addFilter = (values: CompilerFreePassInterface, form: HTMLFormElement) => {
    values.temp_id = uuidv4()
    setFilters(prev => [...prev, values])
    form.reset()
    setResetSecondarySelects(!resetSecondarySelects)
  }

  const deleteFilter = ({ temp_id, id }: CompilerFreePassInterface) => {
    console.log(temp_id)
    id && setFiltersIdsForDelete(prev => [...prev, id])
    setFilters(prev => prev.filter(e => id !== e.id || temp_id !== e.temp_id))

  }

  // Formulario prueba de filtros
  const testFilters = (values: CompilerFreePassInterface) => {
    const resourcesForm = document.getElementById('resources-form') as HTMLFormElement
    const keys = Object.keys(values)
    const query_filters = keys.length ? keys.reduce((acc, property, i, arr) => {
      const { field, operator } = filters.find(e => e.name === property) as CompilerFreePassInterface
      const dataType = columnsTypes[field] || 'text'
      const method = filterMethods[operator]
      const formater = formaters[method.formater]

      return `${acc} ${formater({
        ...method,
        data: dataType === 'text' ? `'${values[property]}'` : values[property],
        field
      })} ${i === arr.length - 1 ? '' : 'AND'}`

    }, 'WHERE') : ''

    functions['test-query']({ ...getValues(resourcesForm), query_filters })
  }
  const createPreview = (input: HTMLInputElement, values: CompilerFreePassInterface) => {
    const keys = Object.keys(values)
    setFiltersPreview(keys.map((property, i) => <span key={uuidv4()}><span className='font-semibold'>{!i ? 'Preview: ?' : '&'}</span><span className='font-bold'>{property}</span>=<span className='font-bold text-text-disabled'>{values[property]}</span></span>))
  }

  // Columnas para la tabla de filtros
  const tableColumns = [
    {
      label: 'Nombre',
      property: 'name'
    },
    {
      label: 'Campo',
      property: 'field'
    },
    {
      label: 'Operador',
      property: 'operator',
      cell: (data: string) => filterMethods[data]?.display_name
    },
    {
      label: 'Acciones',
      center: true,
      cell: (data: CompilerFreePassInterface) => <button onClick={() => deleteFilter(data)} className='px-5 py-2 w-full h-full flex justify-center text-danger items-center' type='button'>Borrar <MdDelete /></button>
    }
  ]
  // Manejar el cambio a consulta avanzada
  const handleChangeAdvancedQuery = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement

    if (filters.length && !advancedQueryAlertConfirmed) {
      Alert.fire({
        title: '¿Seguro?',
        text: "Los filtros se desactivarán",
        icon: "warning",
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar",
        showCancelButton: true
      }, () => {
        setAdvancedQuery(true)
        setAdvancedQueryAlertConfirmed(true)
      })
    } else {
      setAdvancedQuery(target.checked)
    }
  }
  const handleChangeSkipPagination = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement
    setSkipPagination(target.checked)

  }

  // Ciclo de vida
  useEffect(() => {
    presenter.present()
    // Obtener origenes de datos
    presenter.loadDataSources()

    // Obtener metodos para filtros
    presenter.loadFilterMethods()
    // Si existe el parametro id, traemos la api a editar
    if (id) {
      presenter.loadResourceToEdit(id)
      presenter.loadResourceFilters(id)
    }
  }, [id]) // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(()=> {
    const selectedDatasource = dataSourceOptions.find(option => option.value === resourceToEdit?.datasource)?.object
    disablePagination(selectedDatasource?.client)
    console.log('datasources', dataSourceOptions)
  },[dataSourceOptions,resourceToEdit])

  return (
    <div className='p-4 grid grid-cols-1 content-start'>
      <h1 className='font-bold m-3 text-lg self-start'>{id ? 'Editar recurso' : 'Crear recurso'}</h1>
      {/* Formulario principal */}
      <Form listenChange={['datasource_id', 'database', 'table', 'columns', 'queryString', 'limit1', 'limit2', 'advanced_query']} onChange={() => setIsQueryTested(false)} onSubmit={handleSubmit} id='resources-form'>
        <div className='border-t border-b border-bg py-2 mx-2'>
          <h2 className='font-bold mx-3 text-base self-start'>Conexión</h2>
          <div className='grid grid-cols-2'>
            <InputSelect disabled={id} reset={resetMainSpecialFields} onSelect={handleDataSourceChange} options={dataSourceOptions} name="datasource_id" placeholder="Elige una opcion" label="Origen de datos" required defaultValue={resourceToEdit?.datasource} />
            <InputSelect disabled={id} reset={resetMainSpecialFields} onSelect={handleDataBaseChange} options={dataBaseOptions} name="database" placeholder="Elige una opcion" label="Base de datos" required defaultValue={resourceToEdit?.database} />
          </div>
        </div>

        <div className='py-2 mx-2'>
          <div className='flex'>
            <h2 className='font-bold mx-3 text-base self-start'>Diseño de consulta</h2>
            <div className='flex items-center bg-bg rounded-lg focus:outline-none px-3 w-fit'>
              <input disabled={id ? true : false} onChange={handleChangeAdvancedQuery} type="checkbox" name='advanced_query' checked={advancedQuery} />
              <label className='text-sm font font-semibold mx-1'>Consulta avanzada</label>
            </div>
            <div className='flex items-center bg-bg rounded-lg focus:outline-none px-3 w-fit mx-1'>
              <input disabled={disablePaginationOnClient} type="checkbox" name='skip_pagination' onChange={handleChangeSkipPagination} checked={disablePaginationOnClient || skipPagination} />
              <label className='text-sm font font-semibold mx-1'>Desactivar paginacion</label>
            </div>
          </div>
          {/* Diseño de consulta por selects */}
          <Colapsable disabled={advancedQuery} childClassName={`grid grid-cols-3`}>
            <InputSelect disabled={id} reset={resetMainSpecialFields} onSelect={handleTableChange} options={tableOptions} name="table" placeholder="Elige una opcion" label="Tabla" required={!advancedQuery} defaultValue={resourceToEdit?.table} />
            <InputMultiSelect reset={resetMainSpecialFields} options={columnsOptions} name='columns' placeholder='Todas' label='Columnas' required={!advancedQuery} defaultValue={resourceToEdit?.columns} />
            <Input name="limit1" placeholder={disablePaginationOnClient || skipPagination ? "Sin limite" : 'Es 10 por defecto'} label='Limite' defaultValue={resourceToEdit?.limit} />
          </Colapsable>
        </div>

        {/* Editor sql */}
        <Colapsable disabled={!advancedQuery}>
          <div className='h-56 overflow-y-auto overflow-x-hidden bg-bg p-1 rounded-lg rounded-bl-none'>
            <SqlEditor required={advancedQuery} name='queryString' defaultValue={resourceToEdit?.query} reset={resetMainSpecialFields} />
          </div>
          <Input template={<span className='font-semibold'>Limite:</span>} className='m-0 w-56 rounded-b-lg bg-bg' name="limit2" type='number' placeholder={disablePaginationOnClient || skipPagination ? "Sin limite" : 'Es 10 por defecto'} defaultValue={resourceToEdit?.limit} />
        </Colapsable>

        {/* Aqui se llama el portal para poder insertar un formulario independiente dentro de otro formulario */}
        <div id='filter-form' />
        <Colapsable className='m-2' title='Resultado de la consulta'>
          <div className='mb-4'>
            {
              tabs[tabIndex].component
            }
            <div className='mx-4'>
              {
                tabs.map((e, i) => <button key={e.label + i} type='button' onClick={() => setTabIndex(i)} className={`px-4 border-b border-x rounded-b-lg border-bg text-sm hover:text-primary ${i === tabIndex && 'font-bold'}`}>{e.label}</button>)
              }
            </div>
          </div>
          <div className='flex w-full justify-end'>
            <button id='test-query' onClick={() => setIsQueryTested(false)} className="btn-primary flex items-center justify-center w-32" type='submit'>Probar consulta</button>
          </div>
        </Colapsable>

        <div className='border-t border-bg py-2 my-2'>
          <h2 className='font-bold mx-3 text-base self-start'>Datos endpoint</h2>
          <div className='flex flex-col md:flex-row w-full'>
            <Input type='text' required={isQueryTested} name='name' placeholder='ej: Productions' label='Nombre' defaultValue={resourceToEdit?.name} />
            <Input type='text' required={isQueryTested} name='endpoint' placeholder='ej: /productions' template='/' label='Ruta de contexto' defaultValue={resourceToEdit?.endpoint} />
          </div>
        </div>

        <div className='flex w-full justify-end'>
          <button onClick={() => navigate(-1)} className="btn-primary flex items-center justify-center w-32" type='button'>Cancelar</button>
          <button title={!isQueryTested ? 'Necesitas probar tu consulta primero' : ''} disabled={!isQueryTested} id='main-submit' className={`${isQueryTested ? 'btn-primary' : 'btn-bg'} flex items-center justify-center w-32`} type='submit'>{id ? 'Editar' : 'Crear'}</button>
        </div>
      </Form>

      {/* Formulario de filtros por ruta (Portal) */}
      {document.getElementById('filter-form') ?
        <Portal node={document.getElementById('filter-form')}>
          <Form onSubmit={addFilter}>
            <Colapsable disabled={advancedQuery} className='border-y border-bg m-2 py-2' title='Filtros como parametros de ruta'>
              <div className='grid grid-cols-1 lg:grid-cols-3 gap-2 w-full'>
                <InputSelect reset={resetSecondarySelects} options={filterMethodsOptions} required type='text' label='Metodo' name='operator' placeholder='Elige una opcion' />
                <InputSelect reset={resetSecondarySelects} options={columnsOptions} required type='text' label='Columna' name='field' placeholder='Elige una opcion' />
                <Input name='name' label='Nombre' required placeholder='Ej: buscar-provincia' />
              </div>
              <div className='flex w-full justify-end'>
                <button className="btn-primary flex items-center justify-center w-32" type='submit'>Añadir filtro</button>
              </div>
              <div className='bg-main border h-56 border-bg overflow-x-auto rounded-lg my-2 py-2'>
                <Table noDataMessage='No se agregaron parametros' data={filters} columns={tableColumns} />
              </div>
            </Colapsable>

          </Form>
        </Portal> : null}

      {/* Utilizamos el hook use modal para levantar el modal donde especificaremos los datos de los filtros para probar la query */}
      <FiltersModal onClose={() => setFiltersPreview(null)}>
        <Form onChange={createPreview} onSubmit={testFilters}>
          <h2 className='font-bold mx-3 text-base self-start'>Probar filtros</h2>
          {filters.map(({ name, field, temp_id, id }) => {
            return <Input type={columnsTypes[field] || 'text'} step='any' key={temp_id || id} label={name} name={name} placeholder={`Dato de prueba para el campo ${field}`} />
          })}
          <small className='font-semibold mx-3 text-text-disabled'>Dejar en blanco para ignorar campos</small>
          <div className='p-3'>
            <small>{filtersPreview}</small>
          </div>
          <div className='flex w-full justify-end'>
            <button className="btn-primary flex items-center justify-center w-32" type='submit'>Probar consulta</button>
          </div>
        </Form>
      </FiltersModal>
    </div>
  )
}

export default AddOrEditresources