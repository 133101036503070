import { ILoadClientsAction } from '../../core/actions/loadClientsAction'
import { ILoadDatasourceToEditAction } from '../../core/actions/loadDatasourceToEditAction'
import { ISaveDatasourceAction } from '../../core/actions/SaveDatasourceAction'
import { ITestConnectiontAction } from '../../core/actions/testConnectionAction'
import IDatasourceAddOrEditViewHandlers from '../../core/views/IDatasourceAddOrEditViewHandlers'

export const DatasourcesAddOrEditPresenter = (
  viewHandlers: IDatasourceAddOrEditViewHandlers,
  loadClientsAction: ILoadClientsAction,
  loadDatasourceToEditAction: ILoadDatasourceToEditAction,
  testConnectionAction: ITestConnectiontAction,
  saveDatasourceAction: ISaveDatasourceAction
) => {
  return {
    present() {
      console.log('AddOrEdita de origenes renderizada')
    },
    
    loadClients() {
      const loadClientsRequest = loadClientsAction.execute()
      loadClientsRequest.then((res) => {
        viewHandlers.loadClientsOk(res)
      })
    },

    loadDatasourceToEdit(id: string) {
      const loadDatasourceToEditRequest = loadDatasourceToEditAction.execute(id)

      loadDatasourceToEditRequest.then((res) => {
        viewHandlers.loadDatasouceToEditOk(res)
      })
    },

    testConnection(values:any) {
      const testConnectionRequest = testConnectionAction.execute(values)

      testConnectionRequest.then(res => {
        viewHandlers.testConnectionOk(res)
      }).catch(()=> {
        viewHandlers.testConnectionFail()
      })
    },
    saveDatasource(values:any,id:string) {
      const saveDatasourceRequest = saveDatasourceAction.execute(values,id)
      saveDatasourceRequest.then(res => {
        viewHandlers.saveDatasourceOk(res)
      }).catch(()=> {
        viewHandlers.saveDatasourceFail()
      })
    }
  }
}