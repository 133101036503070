import Swal from 'sweetalert2';

const StyledAlert = Swal.mixin({
  customClass: {
    confirmButton: "btn-primary mx-1 px-2 w-fit",
    cancelButton: "btn-bg mx-1 px-2 w-fit",
    title: "text-text",
    htmlContainer: "text-text",
  },
  buttonsStyling: false,
  background: "#f9f9f9"
});

interface CompilerFreePassInterface {
  [key:string] :any
}

class Alert {
  fire (options:CompilerFreePassInterface, confirmCallBack?: ()=> any) {
    StyledAlert.fire(options).then(({isConfirmed}) => (isConfirmed && confirmCallBack) && confirmCallBack())
  }
}

export default new Alert()