import { IRequest } from "../../../../types/http";
import ILoginGateway from "../../core/gateways/ILoginGateway";

const LoginGateway = (httpRequest: IRequest): ILoginGateway => ({
    async authenticate(values) {
     return httpRequest('/login',{
        values,
        method: 'POST'
     }).then(({result}) => result)
    }
})

export default LoginGateway
