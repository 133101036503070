import Router from "./router";
import Navigation from "./components/commons/Navigation";
import { DependenciesContextProvider } from "./contexts";
import { DependencyManager } from "./dependencyManager";
import { resourcesModuleInitilize } from "./modules/resources/resourcesModule";
import { datasourceModuleInitilize } from "./modules/datasources/datasourcesModule";
import { loginModuleInitializer } from "./modules/login/LoginModule";
import useRequest from "./hooks/useRequest";
import 'animate.css'
import { useEffect, useState } from "react";
function App() {
  const [dependencyManager] = useState<DependencyManager>(new DependencyManager())
  const [dependenciesReady, setDependenciesReady] = useState<boolean>(false)
  const request = useRequest()
  
  useEffect(()=> {
    dependencyManager.register('request', request, true)
    resourcesModuleInitilize(dependencyManager)
    datasourceModuleInitilize(dependencyManager)
    loginModuleInitializer(dependencyManager)
    setDependenciesReady(true)
  },[dependencyManager,request])

  return (
    <div className="text-text bg-bg grid grid-cols-12 h-screen">
      <DependenciesContextProvider dependencyManager={dependencyManager}>
        <Navigation/>
        <Router dependenciesReady={dependenciesReady}/>
      </DependenciesContextProvider>
    </div>
  );
}

export default App;

