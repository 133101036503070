import { ILoadColumnsAction } from "../../core/actions/LoadColumnsAction"
import { ILoadDataBasesAction } from "../../core/actions/LoadDataBasesAction"
import { ILoadDataSourcesAction } from "../../core/actions/LoadDataSourcesAction"
import { ILoadFilterMethodsAction } from "../../core/actions/LoadFilterMethodsAction"
import { ILoadResourceFiltersAction } from "../../core/actions/LoadResourceFiltersAction"
import { ILoadResourceToEditAction } from "../../core/actions/LoadResourceToEditAction"
import { ILoadTablesAction } from "../../core/actions/LoadTablesAction"
import { ISaveResourceAction } from "../../core/actions/SaveResourceAction"
import { ITestQueryAction } from "../../core/actions/TestQueryAction"
import IResourcesAddOrEditViewHandlers from "../../core/views/IResourcesAddOrEditViewHandlers"
export const ResourcesAddOrEditPresenter = (
  viewHandlers: IResourcesAddOrEditViewHandlers,
  loadFilterMethodsAction: ILoadFilterMethodsAction,
  loadResourceToEditAction: ILoadResourceToEditAction,
  loadResourceFiltersAction: ILoadResourceFiltersAction,
  loadDataSourcesAction: ILoadDataSourcesAction,
  loadDataBasesAction: ILoadDataBasesAction,
  loadTablesAction: ILoadTablesAction,
  loadColumnsAction: ILoadColumnsAction,
  saveResourceAction: ISaveResourceAction,
  testQueryAction: ITestQueryAction
) => {
  return {
    present() {
      console.log('Vista añadir o editar recursos renderizada')
    },

    loadFilterMethods() {
      const loadFilterMethodsRequest = loadFilterMethodsAction.execute()
      loadFilterMethodsRequest.then((res) => {
        viewHandlers.loadFilterMethodsOk(res)
      })
    },

    loadResourceToEdit(id: string) {
      const loadResourceToEditRequest = loadResourceToEditAction.execute(id)
      loadResourceToEditRequest.then((res) => {
        viewHandlers.loadResourceToEditOk(res)
      })
    },

    loadResourceFilters(id: string) {
      const loadResourceFiltersRequest = loadResourceFiltersAction.execute(id)
      loadResourceFiltersRequest.then((res) => {
        viewHandlers.loadResourceFiltersOk(res)
      })
    },

    loadDataSources() {
      const loadDataSourcesRequest = loadDataSourcesAction.execute()
      loadDataSourcesRequest.then((res) => {
        viewHandlers.loadDataSourcesOk(res)
      })
    },
    loadDataBases(values: any) {
      const loadDataBasesRequest = loadDataBasesAction.execute(values)
      loadDataBasesRequest.then((res) => {
        viewHandlers.loadDataBasesOk(res)
      })
    },

    loadTables(values: any) {
      const loadTablesRequest = loadTablesAction.execute(values)
      loadTablesRequest.then((res) => {
        viewHandlers.loadTablesOk(res)
      })
    },

    loadColumns(values: any) {
      const loadColumnsRequest = loadColumnsAction.execute(values)
      loadColumnsRequest.then((res) => {
        viewHandlers.loadColumnsOk(res)
      })
    },
    
    saveResource(values: any, id: string | undefined, form: HTMLFormElement) {
      const saveResourceRequest = saveResourceAction.execute(values, id)
      saveResourceRequest.then((res) => {
        console.log('save resource ok')
        viewHandlers.saveResourceOk(res, form)
      })
    },

    testQuery(values:any) {
      const testQueryRequest = testQueryAction.execute(values)
      testQueryRequest.then(res => {
        viewHandlers.testQueryOk(res)
      })
    }


  }
}