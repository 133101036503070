import React, { useEffect, useState } from 'react';
import Table from './Table';
import { capitalize } from '../../utils';
interface CompilerFreePassInterface {
    [key:string] :any
  }
const TableFromQuery = ({ result, noDataMessage }:CompilerFreePassInterface) => {
    const [parsedData, setParsedData] = useState([])
    const [columns, setColumns] = useState<CompilerFreePassInterface[]>([])
    useEffect(() => {
        const parsedResult= JSON.parse(result || '[]')
        if (parsedResult.length) {
            const keys= Object.keys(parsedResult[0])
            const data = parsedResult.map((element:CompilerFreePassInterface) => {
                return keys.reduce((a,c,i) => ({...a, [c || `Columna ${i+1}`]: element[c]}),{})
            })
            if (data[0]) {
                const columns = Object.keys(data[0])?.map(element => ({ label: capitalize(element), property: element}))
                setParsedData(data)
                setColumns(columns)
            }
        } else {
            setParsedData([])
            setColumns([])
        }
    }, [result])
    return (
        <Table columns={columns} data={parsedData} noDataMessage={noDataMessage} />
    );
};

export default TableFromQuery;