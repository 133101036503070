import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ILoadTablesAction {
    execute: (values:any) => Promise<any>;
}

export const LoadTablesAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ILoadTablesAction => {
    return {
        execute: async (values): Promise<any> => {
            return ResourcesAddOrEditGateway.loadTables(values)
        }
    }
}