import { createContext, ReactNode } from "react";
import {DependencyManager} from '../dependencyManager';
interface ContextProviderPropsInterface {
    dependencyManager : DependencyManager,
    children: ReactNode
}

export const DependenciesContext = createContext<DependencyManager>(
  {} as DependencyManager,
);
export function DependenciesContextProvider({children, dependencyManager}:ContextProviderPropsInterface) {
  return (
    <DependenciesContext.Provider value={dependencyManager}>
      {children}
    </DependenciesContext.Provider>
  );
}
