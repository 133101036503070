import { DependencyManager } from "../../dependencyManager";
import { IRequest } from "../../types/http";
import { DatasourcesAddOrEditGateway } from "./infrastruture/gateways/DatasourceAddOrEditGateway";

import { DatasourcesListGateway } from "./infrastruture/gateways/DatasourceListGateway";

export const datasourceModuleInitilize = (dependencyManager:DependencyManager) => {
    const httpRequest = getHttpRequest(dependencyManager)

    const getDatasourcesListGateway = DatasourcesListGateway(httpRequest)
    dependencyManager.register('getDatasourcesListGateway',getDatasourcesListGateway, true)
    
    const getDatasourcesAddOrEditGateway = DatasourcesAddOrEditGateway(httpRequest)
    dependencyManager.register('getDatasourcesAddOrEditGateway',getDatasourcesAddOrEditGateway, true)
}

const getHttpRequest = (dependencyManager: DependencyManager) => {
    return dependencyManager.resolve('request') as IRequest
}