import { useDependency } from "../../../../hooks/useDependency"

import IResourcesListViewHandlers from "../../core/views/IResourcesListViewHandlers"
import { IResourcesListGateway } from "../../core/gateways/IResourcesListGateway"
import { LoadResourcesAction } from "../../core/actions/LoadResourcesAction"
import { ChangeStatusAction } from "../../core/actions/ChangeStatusAction"
import { RemoveResourceAction } from "../../core/actions/RemoveResourceAction"
import { ResourcesListPresenter } from "./ResourcesListPresenter"

import IResourcesAddOrEditViewHandlers from "../../core/views/IResourcesAddOrEditViewHandlers"
import { IResourcesAddOrEditGateway } from "../../core/gateways/IResourcesAddOrEditGateway"
import { LoadDataSourcesAction } from "../../core/actions/LoadDataSourcesAction"
import { ResourcesAddOrEditPresenter } from "./ResourcesAddOrEditPresenter"
import { LoadFilterMethodsAction } from "../../core/actions/LoadFilterMethodsAction"
import { LoadResourceToEditAction } from "../../core/actions/LoadResourceToEditAction"
import { LoadResourceFiltersAction } from "../../core/actions/LoadResourceFiltersAction"
import { LoadDataBasesAction } from "../../core/actions/LoadDataBasesAction"
import { LoadTablesAction } from "../../core/actions/LoadTablesAction"
import { LoadColumnsAction } from "../../core/actions/LoadColumnsAction"
import { SaveResourceAction } from "../../core/actions/SaveResourceAction"
import { TestQueryAction } from "../../core/actions/TestQueryAction"

export const getResourcesListPresenter = ( viewHandlers: IResourcesListViewHandlers) => {
    const resourcesListGateway= ResourcesListGateway()
    const loadResourcesAction= LoadResourcesAction(resourcesListGateway)
    const changeStatusAction = ChangeStatusAction(resourcesListGateway)
    const removeResourceAction = RemoveResourceAction(resourcesListGateway)
    return ResourcesListPresenter(viewHandlers,loadResourcesAction,changeStatusAction,removeResourceAction)
}
export const getResourcesAddOrEditPresenter = (viewHandlers: IResourcesAddOrEditViewHandlers) => {
    const resourcesAddOrEditGateway = ResourcesAddOrEditGateway()
    
    const loadFilterMethodsAction = LoadFilterMethodsAction(resourcesAddOrEditGateway)
    const loadResourceToEditAction = LoadResourceToEditAction(resourcesAddOrEditGateway)
    const loadResourceFiltersAction = LoadResourceFiltersAction(resourcesAddOrEditGateway)
    const loadDataSourcesAction = LoadDataSourcesAction(resourcesAddOrEditGateway)
    const loadDataBasesAction = LoadDataBasesAction(resourcesAddOrEditGateway)
    const loadTablesAction = LoadTablesAction(resourcesAddOrEditGateway)
    const loadColumnsAction = LoadColumnsAction(resourcesAddOrEditGateway)
    const saveResourceAction = SaveResourceAction(resourcesAddOrEditGateway)
    const testQueryAction = TestQueryAction(resourcesAddOrEditGateway)
    
    return ResourcesAddOrEditPresenter(
        viewHandlers, 
        loadFilterMethodsAction,
        loadResourceToEditAction,
        loadResourceFiltersAction,
        loadDataSourcesAction,
        loadDataBasesAction,
        loadTablesAction,
        loadColumnsAction,
        saveResourceAction,
        testQueryAction
        )
}

const ResourcesListGateway = ()=> {
 return useDependency('getResourcesListGateway') as IResourcesListGateway
}

const ResourcesAddOrEditGateway = ()=> {
 return useDependency('getResourcesAddOrEditGateway') as IResourcesAddOrEditGateway
}