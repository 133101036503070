
import { Form, Input } from '../../../../components/libraries/Form';
import logo from '../../../../assets/edbp_logo.png'
import { useNavigate } from 'react-router';
import { MdLogin } from 'react-icons/md';
import { getLoginPresenter } from "../presentation/presenterProvider";
import ILoginViewHandlers from "../../core/views/ILoginViewHandlers";
const Login = () => {
  const viewHandlers: ILoginViewHandlers = {
    loginOk({ token, user }) {
      console.log('Ingreso exitoso, redirigiendo...')
      localStorage.setItem('token', token)
      localStorage.setItem('user_id', user.id)
      navigate('/')
    }
  }
  const presenter = getLoginPresenter(viewHandlers)
  const navigate = useNavigate()

  const handleSubmit = (values:any) => {
    presenter.authenticate(values)
  }
  return (
    <div className='w-full h-full bg-accent_dark grid grid-cols-12 absolute top-0 left-0 p-5'>
      <div className='col-span-8 bg-accent_light rounded-l-lg  text-main flex flex-col justify-center px-4 lg:px-16'>
        <h1 className='text-3xl md:text-5xl font-semibold my-2'>Bienvenido a Api builder platform</h1>
        <p className='my-2 text-lg'>Puedes iniciar sesión con una cuenta existente</p>
      </div>
      <Form onSubmit={handleSubmit} className='col-span-4 px-10 flex flex-col items-center justify-center bg-main rounded-r-lg  '>
        <img className='p-5  w-4/6' src={logo} alt="logo edbp" />
        <div className='w-full flex my-2'>
          <Input placeholder='Ej: admin@gmail.com' label='Email' type='email' name='email' />
        </div>
        <div className='w-full flex my-2'>
          <Input placeholder='Escriba su contraseña' label='Contraseña' type='password' name='password' />
        </div>
        <button className='btn-primary w-fit flex items-center' type='submit'><MdLogin className='mr-2' />Iniciar Sesion</button>
      </Form>
    </div>
  );
};

export default Login;