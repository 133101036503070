import { DependencyManager } from "../../dependencyManager";
import { IRequest } from "../../types/http";
import LoginGateway from "./infrastructure/gateways/LoginGateway";

export const loginModuleInitializer = (dependencyManager: DependencyManager) => {
    const httpRequest = getHttpRequest(dependencyManager)
    dependencyManager.register('getLoginGateway', LoginGateway(httpRequest), true)
}

const getHttpRequest = (dependencyManager: DependencyManager) => {
    return dependencyManager.resolve('request') as IRequest
}
