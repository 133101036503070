import { IRequestResponse } from "../../../../types/http";
import { IDatasourcesAddOrEditGateway } from "../../core/gateways/IDatasourcesAddOrEditGateway";
import { formatOptions } from "../../../../utils";
import { IClients, IDatasource, ISaveDatasourceMessage, ITestConnectionMessage } from "../../core/types";

export const DatasourcesAddOrEditGateway = (httpRequest: any): IDatasourcesAddOrEditGateway => {
  return {
    loadClients: async (): Promise<IClients> => {
      return httpRequest(`/datasources/clients`).then(({ result }: IRequestResponse) => {
        const clientOptions = formatOptions(result.clients.array)
        const clientMap = {
          clients: result.clients.object,
          drivers: result.drivers
        }
        return {
          clientOptions,
          clientMap
        }
      })
    },

    loadDatasourceToEdit: async (id): Promise<IDatasource> => {
      return httpRequest(`/datasources/${id}`).then(({ result }: IRequestResponse) => result)
    },
    testConnection: async (values): Promise<ITestConnectionMessage> => {
      return httpRequest('/datasources/test-connection',{
        values,
        method:'POST'
      }).then(({msg}:IRequestResponse) => msg)
    },
    saveDatasource: async (values, id = ''): Promise<ISaveDatasourceMessage> => {
      return httpRequest(`/datasources/${id}`, {
        values,
        method: id ? 'PATCH' : 'POST'
      }).then(({ msg }: IRequestResponse) => msg)
    }
  }
}
