import { IDatasourcesAddOrEditGateway } from "../gateways/IDatasourcesAddOrEditGateway";
import { ISaveDatasourceMessage } from "../types";
export interface ISaveDatasourceAction {
    execute: (values:any,id?:string) => Promise<ISaveDatasourceMessage>;
}

export const SaveDatasourceAction = (DatasourcesAddOrEditGateway:IDatasourcesAddOrEditGateway): ISaveDatasourceAction => {
    return {
        execute: async (values:any,id=''): Promise<ISaveDatasourceMessage> => {
            return DatasourcesAddOrEditGateway.saveDatasource(values,id)
        }
    }
}