import { IRequest, IRequestResponse } from "../../../../types/http";
import { IResourcesListGateway} from "../../core/gateways/IResourcesListGateway";
import { IResources, IRemoveResourceMessage, IChangeStatusMessage  } from "../../core/types";

export const ResourcesListGateway = (httpRequest:IRequest): IResourcesListGateway => {
    return {
        loadResources: async (page_number): Promise<IResources> => {
            const userId = localStorage.getItem('user_id')
            return httpRequest(`/resources?owner=${userId}&page_number=${page_number}`).then((res:IRequestResponse) => (
                {
                    items: res.result,
                    pagination: res.pagination
                }
            ))
        },
        
        removeResource: async (id): Promise<IRemoveResourceMessage> => {
            return httpRequest(`/resources/${id}`, {
                method: 'DELETE'
            }).then(({ msg }:IRequestResponse) => msg)
        },

        changeStatus: async (id,status): Promise<IChangeStatusMessage> => {
            return httpRequest(`/resources/${id}`, {
                method: 'PATCH',
                values: {status:!status} 
              }).then((res:IRequestResponse) => `Actualizaste el estado de ${res.result.name}`)
        }
    }
}
