import { useState, useEffect } from 'react';
import Table from '../../../components/libraries/Table';
import Dropdown from '../../../components/libraries/Dropdown';
import { MdDelete, MdEdit } from "react-icons/md";
import Alert from '../../../components/libraries/Alert';
import DatasourcesAddOrEdit from './DatasourcesAddOrEdit';
import Modal from '../../../components/libraries/Modal';
import {getDatasourcesPresenter} from '../infrastruture/presentation/presenterProvider'
import IDatasourceListViewHandlers from '../core/views/IDatasourcesListViewHandlers';
import { IResources } from '../../resources/core/types';
import Pagination from '../../../components/libraries/Pagination';
interface CompilerFreePassInterface {
  [key:string] :any
}
const DataSourcesList = () => {
  const [dataSources, setDataSources] = useState<IResources>()
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')

  const viewHandlers: IDatasourceListViewHandlers = {
    loadDatasourcesOk(resources) {
        setDataSources(resources)
    },
    removeDatasourceOk(message) {
        setLoading(false)
        Alert.fire({title:"¡Perfecto!", text: message, icon: 'success'})
    },
  }
  const presenter = getDatasourcesPresenter(viewHandlers)
  const remove= ({id, name}:CompilerFreePassInterface) => {
    Alert.fire({
      title: `Esta por eliminar ${name}`,
      text: "Esta accion no se puede revertir",
      icon: "warning",
      confirmButtonText: "Eliminar Api",
      cancelButtonText: "Cancelar",
      showCancelButton: true
    },()=> {
      setLoading(true)
      presenter.removeDatasource(id)
    })
  }
  const edit= ({id}:CompilerFreePassInterface) => {
      setId(id)
  }
  const handlePageChange= (page:number) => {
    presenter.loadDatasources(page)
  }
  const dropdownOptions= [
    {label: <span className='flex items-center justify-center py-2 text-sm'>Editar<MdEdit className='mx-2'/></span>, action: edit},
    {label: <span className='flex items-center justify-center py-2 text-sm'>Remover<MdDelete className='mx-2'/></span>, action: remove}
  ]
  const columns = [
    {
      label: 'Nombre de la conexión',
      property: 'name',
    },
    {
      label: 'Cliente',
      property: 'client',
    },
    {
      label: 'Usuario',
      property: 'user',
    },
    {
      label: 'Acciones',
      center: true,
      cell: (o:CompilerFreePassInterface) => <Dropdown object={o} options={dropdownOptions}/>
    },
  ]


  useEffect(() => {
    if (loading) return
    presenter.present()
    presenter.loadDatasources()
  }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className='grid-rows-12 grid-cols-12 grid'>
      <div className='w-full col-span-12 flex justify-end'>
        <Modal {...{
          label:id ? 'Continuar edición' :'Añadir Origen', 
          onClose:(action:string) => action === 'close' &&  setId(''), 
          loading, 
          setLoading, 
          id,
          setId,
          openOn:id,
          Component: DatasourcesAddOrEdit
          }} />
      </div>
      <div className='col-span-12 row-span-11 h-full bg-main overflow-x-auto rounded-lg'>
        <Table data={dataSources?.items} columns={columns} noDataMessage='No hay origenes'/>
      </div>
      <Pagination className='bg-main w-full self-end col-span-12 p-2' onPageChange={handlePageChange} data={dataSources?.pagination}/>
    </div>
  );
};

export default DataSourcesList;