import { IResourcesListGateway, IRemoveResourceMessage } from "../gateways/IResourcesListGateway";
export interface IRemoveResourceAction {
    execute: (id:string) => Promise<IRemoveResourceMessage>;
}

export const RemoveResourceAction = (ResourcesListGateway:IResourcesListGateway): IRemoveResourceAction => {
    return {
        execute: async (id): Promise<IRemoveResourceMessage> => {
            return ResourcesListGateway.removeResource(id)
        }
    }
}