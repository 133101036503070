import { IRequestResponse } from "../../../../types/http";
import { IResourcesAddOrEditGateway } from "../../core/gateways/IResourcesAddOrEditGateway";
import { formatOptions, dataTypeIndetifier } from "../../../../utils";
import { ISelectOptions, IFilters, IFiltersMethods, IResource, IColumns,ISaveResourceMessage, IQueryResponse } from "../../core/types";


export const ResourcesAddOrEditGateway = (httpRequest: any): IResourcesAddOrEditGateway => {
  return {
    loadFilterMethods: async (): Promise<IFiltersMethods> => {
      return httpRequest('/filters/methods').then(({ result }: IRequestResponse) => {
        return {
          methods: result.object,
          methodsOptions: formatOptions(result.array, 'name', 'display_name')
        }
      })
    },

    loadResourceToEdit: async (id: string): Promise<IResource> => {
      return httpRequest(`/resources/${id}`).then(({result}:IRequestResponse) => result)
    },

    loadResourceFilters: async (id: string): Promise<IFilters> => {
      return httpRequest(`/resources/filters/${id}`).then(({result}:IRequestResponse) => result)
    },

    loadDataSources: async (): Promise<ISelectOptions> => {
      const userId = localStorage.getItem('user_id')
      return httpRequest(`/datasources?owner=${userId}`).then(({ result: datasources }: IRequestResponse) => formatOptions(datasources, 'id', 'name'))
    },

    loadDataBases: async (values): Promise<ISelectOptions> => {
      return httpRequest(`/datasources/databases`, {
        values,
        method: 'POST'
      }).then(({ result: databases }: IRequestResponse) => {
        const property = Object.keys(databases[0])[0]
        return formatOptions(databases, property)
      })
    },

    loadTables: async (values): Promise<ISelectOptions> => {
      return httpRequest(`/datasources/tables`, {
        values,
        method: 'POST'
      }).then(({ result: tables }: IRequestResponse) => {
        const property = Object.keys(tables[0])[0]
        return formatOptions(tables, property)
      })
    },

    loadColumns: async (values): Promise<IColumns> => {
      return httpRequest(`/datasources/columns`, {
        values,
        method: 'POST'
      }).then(({ result }: IRequestResponse) => {
        const columns = Object.keys(result[0])
        const types = columns.reduce((a, c) => ({ ...a, [c]: dataTypeIndetifier(result[0][c]) }), {})
        return {
          options: formatOptions(columns),
          types
        }
      })
    },

    saveResource: async ({ filters, filtersIdsForDelete, ...values }, id = ''): Promise<ISaveResourceMessage> => {
      return new Promise(async (resolve, reject) => {
        try {
          const {result, msg} = await httpRequest(`/resources/${id}`, {
            values,
            method: id ? 'PATCH' : 'POST'
          })
          const filtersToSave = filters.filter((e: any) => !e.id)
          if (filtersIdsForDelete.length) {
            await httpRequest(`/filters`, {
              values: filtersIdsForDelete,
              method: 'DELETE'
            })
          }
          if (filtersToSave.length) {
            await httpRequest(`/filters`, {
              values: filtersToSave.map((e: any) => ({ ...(e as object), resource_id: result.id })),
              method: 'POST'
            }).then(({success}: IRequestResponse)=> {
              if(success) resolve(msg)
            })
          } else {
            resolve(msg)
          }
        } catch (error) {
          reject(error)
        }
      })
    },

    testQuery: async (values): Promise<IQueryResponse> => {
      return httpRequest('/datasources/query', {
        values,
        method: 'POST'
      }).then(({ result }: IRequestResponse) => result)
    }
  }
}