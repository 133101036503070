import React, {useState, useEffect, FormEventHandler, MouseEventHandler, WheelEventHandler, ChangeEventHandler} from 'react'

import AceEditor from "react-ace"

import "ace-builds/src-min-noconflict/ext-language_tools"
import "ace-builds/src-min-noconflict/mode-mysql"
import "ace-builds/src-noconflict/theme-github"
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { MdKeyboardArrowDown } from 'react-icons/md'
import useRequest from '../../../hooks/useRequest'
import { isJSON } from '../../../utils'
interface CompilerFreePassInterface {
    [key:string] :any
  }
export const getValues= (form: HTMLFormElement) => {
    if(!form) return
    return Array.from(form.getElementsByTagName('input')).reduce((a,{name, value,...rest}) =>
         ((value && name) ? {...a, [name]: rest.hasOwnProperty('checked') ? rest.checked : value} : a),{})
}

export const Form = ({onSubmit, children, className, listenChange, onChange=()=>{}, ...rest}:CompilerFreePassInterface) => {
    const handleSubmit:FormEventHandler<HTMLFormElement>  = (event) => {
        event.preventDefault()
        const target = event.target as Element
        const inputs = Array.from(target.getElementsByTagName('input')).filter(input => !input.closest('fieldset'))
        const fieldsets = Array.from(target.getElementsByTagName('fieldset'))
        const getInputsValues = (inputs: HTMLInputElement[]) => {
            return inputs.reduce((a,{name, value,type,...rest}: HTMLInputElement) => {
                const inputValue = type === 'number' ?Number(value): value
                return ((value && name) ? {...(a as object), [name]: rest.hasOwnProperty('checked') ? rest.checked : isJSON(inputValue) ? JSON.parse(inputValue as string) : inputValue} : a)
            },{})
        }
        const fieldSetsValues = fieldsets.reduce((acc, fieldset: HTMLFieldSetElement) => {
            const inputs = Array.from(fieldset.getElementsByTagName('input'))
            return {...(acc as object), [fieldset.name] : getInputsValues(inputs)}
        },{})
        const values = getInputsValues(inputs)
        
        onSubmit({...values,...fieldSetsValues}, event.target)
    }
    const handleChange: FormEventHandler<HTMLFormElement> = (event)=> {
        const input = event.target as HTMLInputElement | HTMLSelectElement
        const form = input.form as HTMLFormElement
        const formValues=getValues(form)
        if(!listenChange) {
            onChange(input, formValues)
            return
        }
        if(listenChange.includes(input.name)) onChange(input, formValues)
    }

    return (
        <form onChange={handleChange} className={`relative w-full ${className}`}  onSubmit={handleSubmit} {...rest}>
         {children}
        </form>
    );
};

// ----------------------------------------------------------------------------------------------

export const Input = ({ label, template, className,type, ...rest }: CompilerFreePassInterface) => {
    return (
        <div className={`flex flex-col m-2 flex-grow ${rest.disabled && 'text-text-disabled'} ${className}`}>
        {label && <label className='text-sm font font-semibold m-1'>{label}</label>}
        <span className='bg-bg rounded-lg px-3 py-1 flex'>{template && <span className='px-1'>{template}</span>}<input className='focus:outline-none bg-bg w-full' type={type || 'text'} {...rest} /></span>
      </div>
    );
};

// ----------------------------------------------------------------------------------------------


export const InputMultiSelect = ({label, options=[], onSelect, defaultValue, placeholder, reset, required, ...rest}: CompilerFreePassInterface) => {
    const [values, setValues] = useState<Array<string>>([])
    const [isOpen, setIsOpen] = useState(false)
    const handleDropdown= () => {
        if(!options.length) return
        setIsOpen(!isOpen)
    }
    const handleChange: MouseEventHandler<HTMLInputElement | HTMLSelectElement>= (e) => {
        const select= e.target as HTMLInputElement | HTMLSelectElement
        const selected: string = select.value
        if (!selected){
            setValues([])
            onSelect && onSelect([])
            return
        }
        let items
        if(values.includes(selected)){
            items = values.filter(e => e!== selected)
        } else {
            items = [...values, selected]
        }

        onSelect && onSelect(items)

        setValues(items)
    }
    const handleWheelEvent: WheelEventHandler<HTMLDivElement> = (e) => {
        const htmlElement = e.target as Element
        if(htmlElement.tagName ==='DIV') handleDropdown()
    }
    useUpdateEffect(()=> {
        setValues((Array.isArray(defaultValue) ? defaultValue : defaultValue?.split(',') )|| [])
    },[defaultValue,reset])
    
    return (
        <div onWheel={handleWheelEvent} className='flex flex-col m-2 relative flex-grow z-10' >
            {isOpen && <div onClick={handleDropdown} className='w-full h-full fixed top-0 left-0'></div>}
            {label && <label className='text-sm font font-semibold m-1'>{label}</label>}
            <input 
                title={values.join(', ')} 
                onClick={handleDropdown} 
                placeholder={options?.length ? placeholder || 'Elige una opción' : 'Esperando opciones..'} 
                readOnly 
                className='bg-bg rounded-lg pl-3 pr-5 py-1 flex-grow cursor-default focus:outline-none placeholder:text-text placeholder:pl-1' 
                value={ values || defaultValue || []} {...rest} 
                type="text" 
                required={required} 
                />
            <MdKeyboardArrowDown className='absolute right-0 bottom-1' size={20}/>
            <select onClick={handleChange} size={options.length+1 < 20 ? options.length+1 : 20} multiple defaultValue={values} className={`${!isOpen && 'hidden'} bg-bg border border-text absolute top-full w-full z-20 focus:outline-none `}  {...rest}>
                <option className='hover:bg-accent_light hover:text-main px-3 ' onClick={()=> setValues([])} value='' >Default</option>
                {options?.map(({value, label}: CompilerFreePassInterface,i:number) => 
                <option 
                className={`hover:bg-accent_light hover:text-main px-3 ${values.includes(value) && 'bg-text-disabled text-main'}`}  
                key={value + label + i} 
                value={value}>{label}
                </option>)}
            </select>
        </div>

)
}

// ----------------------------------------------------------------------------------------------

export const InputSelect = ({label, options=[], onSelect, defaultValue, placeholder,reset,parentNode, ...rest}: CompilerFreePassInterface) => {
    const [value, setValue] = useState('')
    const handleChange: ChangeEventHandler<HTMLSelectElement>= (e) => {
        const htmlSelect = e.target as HTMLSelectElement
        const form = htmlSelect.form as HTMLFormElement
        const values = getValues(form)
        onSelect && onSelect(e.target, values)
        setValue(e.target.value)
    }
    useEffect(()=> {
        setValue(defaultValue || '')
    },[defaultValue, reset])
    
    return (
        <div className='flex flex-col m-2 flex-grow'>
            {label && <label className='text-sm font font-semibold m-1'>{label}</label>}
            <select value={value} className='bg-bg rounded-lg focus:outline-none px-3 py-1 flex-grow ' onChange={handleChange} {...rest}>
                <option value={''} >{options?.length ? placeholder || 'Elige una opción' : 'Esperando opciones..'}</option>
                {options?.map(({value, label}: CompilerFreePassInterface ,i: number) => <option  key={value + label + i} value={value}>{label}</option> )}
            </select>
            <input readOnly className='hidden' value={ value || defaultValue || ''} {...rest} type="text" />
        </div>

);
};

export const GetFileValueInput = ({ label, className,name,defaultValue,required, ...rest }: CompilerFreePassInterface) => {
    const request = useRequest()
    const [inputValue, setInputValue] = useState('')
    const onFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const fileInput: any = e.target
        const formData = new FormData()
        formData.append('file', fileInput?.files[0])
        request('/getFileValue',{
            method:'POST',
            values:formData,
            skipContentType:true,
            stringifyContent:false

        }).then(res => {
            console.log(res)
            setInputValue(res.result)
        })
        console.log(formData.get('file'))
    }
    return (
        <div className={`flex flex-col m-2 flex-grow ${rest.disabled && 'text-text-disabled'} ${className}`}>
        {label && <label className='text-sm font font-semibold m-1'>{label}</label>}
        <input accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pem" onChange={onFileChange} className='bg-bg block w-full rounded-lg file:transition-colors cursor-pointer focus:outline-none file:bg-text-disabled hover:file:bg-primary file:border-none file:text-main file:cursor-pointer file:px-3 file:py-1' type='file' {...rest} />
        <input required={required} value={JSON.stringify(inputValue || defaultValue)} onChange={()=>{}} className='opacity-0 absolute top-0 left-10' {...rest} name={name} type="text" />
      </div>
    );
}
// ----------------------------------------------------------------------------------------------

export const SqlEditor = ({onChange, table, placeholder, defaultValue, query, readOnly,reset, ...rest}: CompilerFreePassInterface) => {
    const [value, setValue] = useState('')
    const handleChange = (newValue: string) => {
        const value = newValue.replace(/\n/g,' \n').replace(/  +/g,' ')
        onChange && onChange(value)
        setValue(value)
      }

      useUpdateEffect(()=> {
        setValue(query || '')
    },[query])

    useUpdateEffect(()=> {
        setValue(defaultValue || '')
    },[defaultValue, reset])

    return (
        <div className='h-full relative'>
            <AceEditor
            aria-label="editor"
            mode="mysql"
            theme="github"
            name="editor"
            fontSize={16}
            width="100%"
            height='100%'
            showPrintMargin={false}
            showGutter
            readOnly={readOnly}
            placeholder={placeholder || "Escribe tu consulta..."}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
            }}
            onChange={handleChange}
            value={ value || defaultValue || ''}
            />
            <input onChange={()=>{}} className='opacity-0 absolute top-0 left-10' value={ value || defaultValue || ''} {...rest} type="text" />
        </div>
    );
};