import { IRequestResponse } from "../../../../types/http";
import { IDatasourcesListGateway } from "../../core/gateways/IDatasourcesListGateway";
import { IDataSourceRemoveMessage, IDatasources } from "../../core/types";
export const DatasourcesListGateway = (httpRequest: any): IDatasourcesListGateway => {
  return {
    loadDatasources: async (page_number): Promise<IDatasources> => {
      const userId = localStorage.getItem('user_id')
      return httpRequest(`/datasources?owner=${userId}&page_number=${page_number}`).then((res: IRequestResponse) => (
        {
          items: res.result,
          pagination: res.pagination
        }
      ))
    },
    removeDatasources: async (id): Promise<IDataSourceRemoveMessage> => {
      return httpRequest(`/datasources/${id}`,
        {
          method: 'DELETE'
        }).then(({ msg }: IRequestResponse) => msg)
    }
  }
}
