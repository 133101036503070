import React from 'react'
import { useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import './index.css'
interface CompilerFreePassInterface {
  [key:string]:any
}
const Colapsable = ({ children, title, className, disabled=false, childClassName }:CompilerFreePassInterface) => {
  const [expanded, setExpanded] = useState(true)
  const [expanding, setExpanding] = useState(false)
  const [isDisabled, setIsDisable]= useState(disabled)
  const [isComponentMounted, setIsComponentMounted]= useState(false)
  useUpdateEffect(()=> {
      if(isComponentMounted){
        setExpanding(true)
        setIsDisable(disabled)
      } else {
        setIsComponentMounted(true)
      }
  },[disabled])
  return (
    <div onTransitionEnd={() => setExpanding(false)} className={`${className}`}>
      {title && <button disabled={disabled} className={`flex transition-colors ${expanding ? 'bg-bg' : 'bg-main'} w-full items-center rounded  ${disabled && 'text-text-disabled'}`} onClick={() => {
        setExpanded(!expanded)
        setExpanding(true)
      }} type='button'>
        <h2 className={`font-bold mx-3 text-base self-start`}>{title}</h2>
        {expanded ? <MdExpandLess />:<MdExpandMore />}
      </button>}
      <div className={`colapse ${(expanded && !isDisabled) && 'expand'} ${childClassName} ${(!expanding && (expanded && !isDisabled)) && 'show-overflow'}`}>
        {children}
      </div>
    </div>
  )
}

export default Colapsable