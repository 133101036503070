import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ILoadDataSourcesAction {
    execute: () => Promise<any>;
}

export const LoadDataSourcesAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ILoadDataSourcesAction => {
    return {
        execute: async (): Promise<any> => {
            return ResourcesAddOrEditGateway.loadDataSources()
        }
    }
}