import { IDatasourcesAddOrEditGateway } from "../gateways/IDatasourcesAddOrEditGateway";
import { IDatasource } from "../types";
export interface ILoadDatasourceToEditAction {
    execute: (id:string) => Promise<IDatasource>;
}

export const LoadDataSourceToEditAction = (DatasourcesAddOrEditGateway:IDatasourcesAddOrEditGateway): ILoadDatasourceToEditAction => {
    return {
        execute: async (id): Promise<IDatasource> => {
            return DatasourcesAddOrEditGateway.loadDatasourceToEdit(id)
        }
    }
}