import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ILoadDataBasesAction {
    execute: (values:any) => Promise<any>;
}

export const LoadDataBasesAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ILoadDataBasesAction => {
    return {
        execute: async (values): Promise<any> => {
            return ResourcesAddOrEditGateway.loadDataBases(values)
        }
    }
}