export const capitalize = (string: string) => {
  if (typeof string !== 'string') return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const isObject = (obj: any) => {
  if (Object.prototype.toString.call(obj) === '[object Object]') return true;
  return false;
}
export const isJSON = (str:any) => {
  try {
    const json = JSON.parse(str);
    if (json && isObject(json)) return true;
  } catch (e) {
    return false;
  }
  return false;
};

export const formatOptions = (array: any[], value_property?: string, display_property?: string) => {
  if (!array?.length) return []
  return array.map(e => {
    const data = (value_property && e[value_property]) ? e[value_property]?.trim() : e
    const name = (display_property && e[display_property]) ? e[display_property] : capitalize(data).replaceAll('_', ' ')
    return { value: data, label: name, object: e }
  })
}

export const dataTypeIndetifier = (data: any) => {
  const dateRegex = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/
  if (dateRegex.exec(data)) {
    return 'date'
  }
  if (!isNaN(Number(data))) {
    return 'number'
  }
  return 'text'
}

export const routesGenerator = (pages: any) => Object.keys(pages).map((propertyName, i) => {
  const {
    title = propertyName,
    params = '',
    privated = false,
    subRoute = false,
    component: Component
  } = pages[propertyName]
  return ({
    route: (i ? `/${propertyName.toLowerCase().replaceAll(' ', '-')}/` : '/'),
    as: propertyName,
    Component,
    position: i,
    params,
    title,
    privated,
    subRoute
  })
})
export const createHashMap = (objectToMap: {[key:string]: any}, defaultValue?:any) => {
  const handler = {
      get: function(target:{[key:string]:any}, name:string) {
          return target.hasOwnProperty(name) ? target[name] : defaultValue;
        }
  }
  const map = Object.keys(objectToMap).reduce((map, property) => {
      const mapPart = property.split(',').reduce((map, splitedProperty)=> {
          return {...map, [splitedProperty]: objectToMap[property]}
      },{})
      return {...map, ...mapPart}
  },{})
  return new Proxy(map, handler)
}