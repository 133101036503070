import { IResourcesListGateway,IChangeStatusMessage } from "../gateways/IResourcesListGateway";
export interface IChangeStatusAction {
    execute: (id:string,status:boolean) => Promise<IChangeStatusMessage>;
}

export const ChangeStatusAction = (ResourcesListGateway:IResourcesListGateway): IChangeStatusAction => {
    return {
        execute: async (id,status): Promise<IChangeStatusMessage> => {
            return ResourcesListGateway.changeStatus(id,status)
        }
    }
}