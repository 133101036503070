import { IDatasourcesAddOrEditGateway } from "../gateways/IDatasourcesAddOrEditGateway";
import { ITestConnectionMessage } from "../types";
export interface ITestConnectiontAction {
    execute: (values:any) => Promise<ITestConnectionMessage>;
}

export const TestConnectionAction = (DatasourcesAddOrEditGateway:IDatasourcesAddOrEditGateway): ITestConnectiontAction => {
    return {
        execute: async (values): Promise<ITestConnectionMessage> => {
            return DatasourcesAddOrEditGateway.testConnection(values)
        }
    }
}