import ILoginActions from "../../core/actions/ILoginActions"
import ILoginViewHandlers from "../../core/views/ILoginViewHandlers"

const LoginPresenter = (
 viewerHandlers: ILoginViewHandlers,
 actions: ILoginActions
) => ({
    present() {
        console.log('Pantalla de inicio de sesion renderizada')
    },
    authenticate(values: {email:string, password:string}) {
        actions.authenticate(values).then((res)=>{
            viewerHandlers.loginOk(res)
        })
    }
})

export default LoginPresenter