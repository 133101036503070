import useUpdateEffect from '../../../hooks/useUpdateEffect';
import { useState } from 'react';
import {
  Form,
  GetFileValueInput,
  Input,
  InputSelect,
} from '../../../components/libraries/Form';
import Alert from '../../../components/libraries/Alert';
import Loader from '../../../components/commons/Loader/Light';
import { getDatasourcesAddOrEditPresenter } from '../infrastruture/presentation/presenterProvider';
import IDatasourceAddOrEditViewHandlers from '../core/views/IDatasourceAddOrEditViewHandlers';
import { createHashMap } from '../../../utils';
import Colapsable from '../../../components/libraries/Colapsable';
interface CompilerFreePassInterface {
  [key: string]: any;
}

const AddOrEdit = ({
  setId,
  id,
  loading,
  setLoading,
  is_open,
  close_action,
}: CompilerFreePassInterface) => {
  const [isSSHActive, setIsSSHActive] = useState(false);
  const [inputReference, setInputReference] = useState('');
  const [isConnectionOk, setIsConnectionOk] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [clients, setClients] = useState<CompilerFreePassInterface[]>([]);
  const [clientMap, setClientMap] = useState<CompilerFreePassInterface>({});
  const [credentialType, setCredentialType] = useState('password');
  const form = document.getElementsByTagName('form')[0];

  // User for edit
  const [edit, setEdit] = useState<CompilerFreePassInterface | null>(null);
  const [SSHConfig, setSSHConfig] = useState<CompilerFreePassInterface>({});
  const cancelEdit = () => {
    setEdit(null);
    setSSHConfig({});
    setId('');
    setIsConnectionOk(false);
    setInputReference('');
    form.reset();
  };
  const viewHandlers: IDatasourceAddOrEditViewHandlers = {
    loadClientsOk({ clientOptions, clientMap }) {
      setClients(clientOptions);
      setClientMap(clientMap);
    },
    loadDatasouceToEditOk({ datasource, sshConfig }) {
      setEdit(datasource);
      setSSHConfig(sshConfig);
      if (sshConfig) {
        setIsSSHActive(true);
        setCredentialType(sshConfig.credential_type);
      }
      setInputReference(datasource.driver);
    },
    testConnectionOk(msg) {
      Alert.fire({ title: '¡Perfecto!', text: msg, icon: 'success' });
      setLocalLoading(false);
      setIsConnectionOk(true);
    },
    testConnectionFail() {
      setLocalLoading(false);
    },
    saveDatasourceOk(msg) {
      Alert.fire({ title: '¡Perfecto!', text: msg, icon: 'success' });
      setLoading(false);
      form.reset();
      cancelEdit();
      setInputReference('');
      setResetSelect((prev) => !prev);
      setIsConnectionOk(false);
    },
    saveDatasourceFail() {
      setLoading(false);
    },
  };

  const presenter = getDatasourcesAddOrEditPresenter(viewHandlers);

  const mainSubmit = (
    { datasource, isSSHActive, sshConfig }: CompilerFreePassInterface,
    form: HTMLFormElement
  ) => {
    setLoading(true);
    datasource.owner = localStorage.getItem('user_id');
    const values = {
      datasource,
      sshConfig: isSSHActive ? sshConfig : null,
    };
    if (isSSHActive) {
      values.sshConfig.id = SSHConfig.id;
    }

    presenter.saveDatasource(values, id);
  };
  const testConnection = ({
    datasource,
    isSSHActive,
    sshConfig,
  }: CompilerFreePassInterface) => {
    setLocalLoading(true);
    const values = {
      datasource,
      sshConfig: isSSHActive ? sshConfig : null,
    };
    if (isSSHActive) {
      values.sshConfig.decryptAuth = SSHConfig?.id ? true : false;
    }
    console.log(values);
    presenter.testConnection(values);
  };
  const submitFunctions: CompilerFreePassInterface = createHashMap(
    {
      'main-submit': mainSubmit,
      'test-connection': testConnection,
    },
    mainSubmit
  );

  const handleSubmit = (
    values: CompilerFreePassInterface,
    form: HTMLFormElement
  ) => {
    console.log(values);
    const activeElement = document.activeElement as HTMLButtonElement;
    if (activeElement !== null)
      submitFunctions[activeElement.name](values, form);
  };

  const handleUseSSHChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setIsSSHActive(target.checked);
  };
  const variableInputs: CompilerFreePassInterface = {
    athena: {
      Origen: () => (
        <>
          <Input
            defaultValue={edit?.region || ''}
            placeholder="Ej: sa-east-1"
            required
            type="text"
            label="Region"
            name="region"
          />
          <Input
            defaultValue={edit?.host || ''}
            placeholder="Ej: s3://ahtenaResul"
            required
            type="text"
            label="Bucket de resultados"
            name="host"
          />
        </>
      ),
      Credenciales: () => (
        <>
          <Input
            defaultValue={edit?.user || ''}
            autoComplete="new-password"
            placeholder="Ingrese su usuario"
            required
            type="text"
            label="ID de clave de accesos"
            name="user"
          />
          <Input
            autoComplete="new-password"
            placeholder="Ingrese su contraseña"
            required
            type="password"
            label="Clave de acceso secreta"
            name="password"
          />
        </>
      ),
    },
    knex: {
      Origen: () => (
        <>
          <Input
            defaultValue={edit?.host || ''}
            placeholder="Ej: 192.168.6.9"
            required
            type="text"
            label="Host"
            name="host"
          />
          <Input
            defaultValue={edit?.port || ''}
            placeholder="Ej: 3306"
            required
            type="number"
            label="Puerto"
            name="port"
          />
          <Input
            defaultValue={edit?.port || ''}
            type="text"
            label="SID/Service Name"
            name="SID"
          />
        </>
      ),
      Credenciales: () => (
        <>
          <Input
            defaultValue={edit?.user || ''}
            autoComplete="new-password"
            placeholder="Ingrese su usuario"
            required
            type="text"
            label="Usuario"
            name="user"
          />
          <Input
            autoComplete="new-password"
            placeholder="Ingrese su contraseña"
            required
            type="password"
            label="Contraseña"
            name="password"
          />
        </>
      ),
    },
    '': {},
  };
  useUpdateEffect(() => {
    if (close_action === 'close') {
      cancelEdit();
      setIsConnectionOk(false);
      setInputReference('');
      setResetSelect((prev) => !prev);
      setCredentialType('password');
      setIsSSHActive(false);
    }
  }, [close_action]);

  useUpdateEffect(() => {
    if (id) {
      presenter.loadDatasourceToEdit(id);
    }
  }, [id]);

  useUpdateEffect(() => {
    if (!is_open || clients.length) return;
    presenter.present();
    presenter.loadClients();
  }, [is_open]);
  const sshCredentialsOptions = [
    { value: 'password', label: 'Contraseña' },
    { value: 'privated_key', label: 'Clave privada' },
  ];
  const handleCredentialTypeChange = (select: HTMLSelectElement) => {
    setCredentialType(select.value);
  };
  const credentialsInputs: any = {
    password: () => (
      <Input
        defaultValue={SSHConfig.password}
        autoComplete="new-password"
        required={isSSHActive}
        label="Contraseña"
        name="password"
        type="password"
        placeholder="Ingrese una contraseña"
      />
    ),
    privated_key: () => (
      <GetFileValueInput
        defaultValue={SSHConfig.privated_key}
        autoComplete="new-password"
        required={isSSHActive}
        name="privated_key"
        label={SSHConfig ? 'Cambiar clave privada' : 'Clave privada'}
      />
    ),
  };
  const CredentialInput = credentialsInputs[credentialType];
  const { Origen, Credenciales } = variableInputs[inputReference];
  return (
    <div id="datasourcesForms">
      <Form
        name="datasource"
        listenChange={[
          'user',
          'password',
          'port',
          'host',
          'client',
          'privated_key',
          'username',
          'credential_type',
        ]}
        onChange={() => isConnectionOk && setIsConnectionOk(false)}
        onSubmit={handleSubmit}
      >
        <h1 className="font-bold mb-4 px-4 pb-4 border-b border-bg text-lg self-start">
          {edit ? 'Editar Origen' : 'Nuevo Origen'}
        </h1>
        <fieldset name="datasource">
          <div>
            <Colapsable
              className="border-b border-bg mb-4 pb-4"
              title="Configuración del origen"
            >
              <section className="grid grid-cols-2 mx-4">
                <InputSelect
                  reset={resetSelect}
                  defaultValue={edit?.client}
                  required
                  type="text"
                  options={clients}
                  label="Cliente"
                  name="client"
                  onSelect={(target: HTMLSelectElement) =>
                    setInputReference(
                      clientMap.drivers[clientMap.clients[target.value]] || ''
                    )
                  }
                />
                <Input
                  defaultValue={edit?.name}
                  placeholder="Ej: Conexión MySql"
                  required
                  type="text"
                  label="Nombre de la conexion"
                  name="name"
                />
                {Origen && Origen()}
              </section>
            </Colapsable>
            {Credenciales && (
              <Colapsable
                className="border-b border-bg mb-4 pb-4"
                title="Credenciales"
              >
                <section className="grid grid-cols-2 px-4">
                  {Credenciales()}
                </section>
              </Colapsable>
            )}
          </div>
        </fieldset>
        <div className="flex">
          <h2 className="font-bold mx-3 text-base self-start">
            Configuración del tunel
          </h2>
          <div className="flex items-center bg-bg rounded-lg focus:outline-none px-3 w-fit">
            <input
              disabled={id ? true : false}
              onChange={handleUseSSHChange}
              type="checkbox"
              name="isSSHActive"
              checked={isSSHActive}
            />
            <label className="text-sm font font-semibold mx-1">Usar SSH</label>
          </div>
        </div>
        <Colapsable disabled={!isSSHActive}>
          <fieldset name="sshConfig">
            <section className="grid grid-cols-2 mx-4">
              <Input
                required={isSSHActive}
                label="Host"
                name="host"
                placeholder="Ej: localhost"
                defaultValue={SSHConfig.host}
              />
              <Input
                required={isSSHActive}
                label="Puerto"
                name="port"
                placeholder="Ej: 3000"
                type="number"
                defaultValue={SSHConfig.port}
              />
              <Input
                required={isSSHActive}
                label="Usuario"
                name="username"
                placeholder="Ingrese un usuario"
                defaultValue={SSHConfig.username}
              />
              <InputSelect
                name="credential_type"
                options={sshCredentialsOptions}
                defaultValue={credentialType}
                onSelect={handleCredentialTypeChange}
                label="Tipo de acceso"
              />
            </section>
            <section className="mx-4">{CredentialInput()}</section>
          </fieldset>
        </Colapsable>
        <div className="flex w-full justify-end">
          {edit && (
            <button onClick={cancelEdit} className="btn-bg" type="button">
              Cancel Edit
            </button>
          )}
          <button
            disabled={loading}
            className={`${
              loading ? 'btn-bg' : 'btn-primary'
            } flex items-center justify-center w-fit`}
            type="submit"
            name={isConnectionOk ? 'main-submit' : 'test-connection'}
          >
            {isConnectionOk ? (edit ? 'Guardar' : 'Crear') : 'Probar conexión'}
            {(loading || localLoading) && <Loader size={5} />}
          </button>
        </div>
      </Form>
    </div>
  );
};

export default AddOrEdit;
