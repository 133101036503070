import ResourcesListScreen from "../modules/resources/views/ResourcesList";
import AddOrEditResourses from "../modules/resources/views/AddOrEditResourses"
import DatasourcesListScreen from "../modules/datasources/views/DatasourcesList"
import { routesGenerator } from "../utils";
import { IComponentProps } from "../types/router";

interface PageInterface {
    [key:string] : {
        component: React.FC<IComponentProps>;
        title?: string;
        params?: string;
        subRoute?: boolean;
        privated?: boolean
    }
}

const pages : PageInterface = {
    'Recursos': {
        component: ResourcesListScreen,
        title: 'Listado de recursos',
    },
    'Recursos/Add': {
        component: AddOrEditResourses,
        title: 'Añadir o editar un recurso',
        subRoute: true,
        params: ':id'
    },
    'Origenes':{
        component: DatasourcesListScreen,
        title: 'Listado de origenes de datos',
    },
    // sample:{
    //     component: sample,
    //     title: 'This is a sample component'
    //     params:':text'
    // },
}
export default routesGenerator(pages)