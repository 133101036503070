import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ILoadFilterMethodsAction {
    execute: () => Promise<any>;
}

export const LoadFilterMethodsAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ILoadFilterMethodsAction => {
    return {
        execute: async (): Promise<any> => {
            return ResourcesAddOrEditGateway.loadFilterMethods()
        }
    }
}