import React, {Fragment} from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import routes from './routes'
import TopBar from '../components/commons/TopBar';
import Login from '../modules/login/infrastructure/views/Login';
import LoadingScreen from './side-components/LoadingScreen';
import useCookies from 'react-cookie/cjs/useCookies';
import useUpdateEffect from '../hooks/useUpdateEffect';
import { IComponentProps, IRoute } from '../types/router';
const URL = process.env.REACT_APP_BASE_URL

const ComponentWrapper = ({ Component, title='', privated, as='', dependenciesReady} :IRoute) => {
  const navigate = useNavigate()
  const location = useLocation()

  useUpdateEffect(()=> {
    const wrapper = document.getElementById(as)?.children[0]
    if(wrapper) wrapper.className += ' bg-main rounded-lg min-h-full'
    if (privated && !localStorage.getItem('token')) {
      // redirect
      navigate('/login')
    }
  },[location.pathname,as,navigate,privated,dependenciesReady])

  const props = {URL} as IComponentProps
  return (
    <div className='col-span-10 xl:col-span-11 grid grid-rows-14 h-screen'>
      <div className='row-span-1'>
        <TopBar title={title} />
      </div>
      <div id={as} className='row-span-13 p-5  overflow-y-scroll'>
        {
          dependenciesReady ? 
          <Component  {...props}/>
          :
          <LoadingScreen/>
        }
      </div>
    </div>
  )
}

const LoginRoute = ({ Component,dependenciesReady }: IRoute) => {
  const [cookie] = useCookies();
  const token = cookie.token || ''
  const props = {URL} as IComponentProps
  const Render = dependenciesReady ? 
  <Component  {...props}/>
  :
  <LoadingScreen/>
  return token ? <Navigate to={routes[0].route} /> : Render
    
  }



const Router = ({dependenciesReady}:{dependenciesReady:boolean}) => {
    return (
          <Routes>
              <Route path='/login' element={<LoginRoute Component={Login} dependenciesReady={dependenciesReady}/>}/>
              {routes.map(({route, as, Component, params, title, privated}) => (
                <Fragment key={as}>
                  <Route path={route} element={<ComponentWrapper {...{Component, title, privated, as, dependenciesReady}} />} />
                  {params && <Route  path={route + params} element={<ComponentWrapper {...{Component, title, privated, as, dependenciesReady}} />} />}
                </Fragment>
              ))}
             <Route path="*" element={<Navigate to={routes[0].route} replace />} />
          </Routes>
    );
};

export default Router;