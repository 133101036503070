import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ISaveResourceAction {
    execute: (values:any,id?:string,) => Promise<any>;
}

export const SaveResourceAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ISaveResourceAction => {
    return {
        execute: async (values:any,id?:string): Promise<any> => {
            return ResourcesAddOrEditGateway.saveResource(values,id)
        }
    }
}