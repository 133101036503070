import { useDependency } from "../../../../hooks/useDependency"
import IDatasourceListViewHandlers from "../../core/views/IDatasourcesListViewHandlers"
import { IDatasourcesListGateway } from "../../core/gateways/IDatasourcesListGateway"
import { DatasourcesListPresenter } from "./DatasourcesListPresenter"
import { LoadDatasourcesAction } from "../../core/actions/loadDatasourcesAction"
import { RemoveDatasourcesAction } from "../../core/actions/removeDatasourceAction"
import IDatasourceAddOrEditViewHandlers from "../../core/views/IDatasourceAddOrEditViewHandlers"
import { IDatasourcesAddOrEditGateway } from "../../core/gateways/IDatasourcesAddOrEditGateway"
import { LoadClientsAction } from "../../core/actions/loadClientsAction"
import { DatasourcesAddOrEditPresenter } from "./DatasourcesAddOrEditPresenter"
import { LoadDataSourceToEditAction } from "../../core/actions/loadDatasourceToEditAction"
import { TestConnectionAction } from "../../core/actions/testConnectionAction"
import { SaveDatasourceAction } from "../../core/actions/SaveDatasourceAction"


export const getDatasourcesPresenter = (viewHandlers: IDatasourceListViewHandlers) => {
    const datasourcesListGateway = DatasourcesListGateway()
    
    const loadDatasourcesAction = LoadDatasourcesAction(datasourcesListGateway)
    const removeDatasourceAction = RemoveDatasourcesAction(datasourcesListGateway)
    return DatasourcesListPresenter(
        viewHandlers, 
        loadDatasourcesAction,
        removeDatasourceAction
        )
}
export const getDatasourcesAddOrEditPresenter = (viewHandlers: IDatasourceAddOrEditViewHandlers) => {
    const datasourceAddOrEditGateway = DatasourceAddOrEditGateway()

    const loadClientsAction = LoadClientsAction(datasourceAddOrEditGateway)
    const loadDatasourceToEditAction = LoadDataSourceToEditAction(datasourceAddOrEditGateway)
    const testConnectionAction = TestConnectionAction(datasourceAddOrEditGateway)
    const saveDatasourceAction = SaveDatasourceAction(datasourceAddOrEditGateway)
    return DatasourcesAddOrEditPresenter(
        viewHandlers,
        loadClientsAction,
        loadDatasourceToEditAction,
        testConnectionAction,
        saveDatasourceAction
    )
}

const DatasourcesListGateway = ()=> {
 return useDependency('getDatasourcesListGateway') as IDatasourcesListGateway
}

const DatasourceAddOrEditGateway = () => {
    return useDependency('getDatasourcesAddOrEditGateway') as IDatasourcesAddOrEditGateway
}