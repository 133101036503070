import { IDatasourcesListGateway } from "../gateways/IDatasourcesListGateway";
import { IDataSourceRemoveMessage } from "../types";
export interface IRemoveDatasourcesAction {
    execute: (id:string) => Promise<IDataSourceRemoveMessage>;
}

export const RemoveDatasourcesAction = (DatasourcesListGateway:IDatasourcesListGateway): IRemoveDatasourcesAction => {
    return {
        execute: async (id): Promise<IDataSourceRemoveMessage> => {
            return DatasourcesListGateway.removeDatasources(id)
        }
    }
}