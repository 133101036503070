import React, { Children, isValidElement, cloneElement, ReactElement, FC } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { MdClose, MdRemove } from "react-icons/md";
interface Props {
  onClose?: (() => void) | ((...parameters: unknown[]) => {} )| undefined
  children?: React.ReactNode;
};

const useModal = ():[FC<Props>, ()=> void, (action?: string, onClose?: ((...parameters: unknown[]) => {}) | undefined)=> void] => {
  const [isOpen, setIsOpen] = useState(false)
  const [closing, setClosing] = useState(false)
  const [close_action, setCloseAction] = useState<string | undefined>(undefined)
  const handleOpen = () => {
    setIsOpen(!isOpen)
    setCloseAction(undefined)
  }
  const handleClose = (action?: string, onClose?: (() => void) | ((...parameters: unknown[]) => {} ) | undefined) => {
    onClose && onClose(action)
    setClosing(true)
    setTimeout(() => {
      setCloseAction(action)
      setIsOpen(false)
      setClosing(false)
    }, 200)
  }
  const Modal:FC<Props> = useCallback(({ children, onClose, ...rest }:Props) => {
    return (
      <div className={`animate__animated ${isOpen ? (closing ? 'animate__fadeOut' : 'animate__fadeIn') : 'hidden'} w-screen h-screen flex flex-col items-center justify-center absolute bg-bg top-0 right-0 bg-opacity-90 z-10 p-5`}>
        <div className={`animate__animated ${closing ? 'animate__zoomOut' : 'animate__zoomIn'} max-h-full my-10 w-7/12 bg-main rounded-lg overflow-y-auto p-2`}>
          <div className='flex w-full justify-end'>
            <button onClick={(e) => handleClose(e.currentTarget.name, onClose)} type='button' name='minimize' className=' font-extrabold p-2 hover:bg-bg rounded-lg'><MdRemove /></button>
            <button onClick={(e) => handleClose(e.currentTarget.name, onClose)} type='button' name='close' className=' font-extrabold p-2 hover:bg-bg rounded-lg'><MdClose /></button>
          </div>
          {Children.map(children, (child) => {
            if (!isValidElement(child)) return child
            return cloneElement(child as ReactElement<any>, {
              ...(child.props as object),
              ...rest,
              is_open: isOpen ? 1 : 0,
              close_action
            })
          })}
        </div>
      </div>
    )
  }, [close_action, closing, isOpen])
  return [Modal, handleOpen, handleClose]
};

export default useModal;