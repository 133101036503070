
import { useNavigate } from "react-router"
import Alert from "../components/libraries/Alert"
const URL = process.env.REACT_APP_BASE_URL || ''
interface CompilerFreePassInterface {
  [key: string]: any
}
const useRequest = () => {
  const navigate = useNavigate()
  const request: (endpoint: any, options?: CompilerFreePassInterface) => Promise<any> = (ENDPOINT:string, {method, values = null,skipContentType,stringifyContent = true}:CompilerFreePassInterface = {})=> {
    const token = localStorage.getItem('token') || ''
    const options:any = {
      method,
      body: (values && stringifyContent )? JSON.stringify(values): values,
      headers: {
        'Authorization': token
      }
    }
    if (!skipContentType) options.headers['Content-Type'] = 'application/json'
    console.log(options)
    return new Promise((resolve, reject) => {
      fetch(`${URL}${ENDPOINT}`, options)
        .then(res => res.json())
        .catch(error => console.error('Error:', error))
        .then((res) => {
          if (res.success) {
            resolve(res)
            return
          }
          if (res.type === 'auth') {
            // localStorage.removeItem('token')
            navigate('/login')
            Alert.fire({ title: res.msg, text: 'Ingrese nuevamente', icon: "warning" });
          } else {
            Alert.fire({ title: "¡Ops!", text: res.msg, icon: 'error' });
          }
          reject(res)

        })
    })
  }
  return request
}
export default useRequest