import ILoginGateway from "../../core/gateways/ILoginGateway";
import ILoginViewHandlers from "../../core/views/ILoginViewHandlers";
import LoginActions from "../actions/LoginActions";
import LoginPresenter from "./LoginPresenter";
import { useDependency } from "../../../../hooks/useDependency";

export const getLoginPresenter = (viewHandlers: ILoginViewHandlers) => {
    const LoginGateway = GetLoginGateway()
    const actions = LoginActions(LoginGateway)

    return LoginPresenter(viewHandlers, actions)

}

const GetLoginGateway = () => {
   return useDependency('getLoginGateway') as ILoginGateway
}