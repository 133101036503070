import { useState, useEffect } from 'react';
import Table from '../../../components/libraries/Table';
import Dropdown from '../../../components/libraries/Dropdown';
import Alert from '../../../components/libraries/Alert';
import { MdLink, MdDelete, MdEdit, MdPowerSettingsNew, MdCopyAll} from "react-icons/md";
import { useNavigate, NavLink } from 'react-router-dom';
import { getResourcesListPresenter } from '../infrastructure/presentation/presenterProvider';
import IResourcesListViewHandlers from '../core/views/IResourcesListViewHandlers'
import { IComponentProps } from '../../../types/router';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Pagination from '../../../components/libraries/Pagination';
import { IResources } from '../core/types';
interface CompilerFreePassInterface {
  [key:string] :any
}

const ResourcesListScreen = ({ URL}:IComponentProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [resources, setResources] = useState<IResources>()
  const navigate = useNavigate()
  
  const viewHandlers:IResourcesListViewHandlers = {
      loadResourcesOk: (resources) => {setResources(resources)},
      changeStatusOk: (message) => {
        Alert.fire({title:"¡Perfecto!", text: message, icon: 'success'});
        setIsLoading(false)
      },
      removeResourceOk: (message) => {
        Alert.fire({title:"¡Perfecto!", text: message, icon: 'success'});
        setIsLoading(false)
      }
    } 
  
  const presenter = getResourcesListPresenter(viewHandlers)
  
  useEffect(() => {
    if(isLoading) return
    presenter.present();
    presenter.loadResources()
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const remove= ({id, name}:CompilerFreePassInterface) => {
    setIsLoading(true)
    Alert.fire({
      title: `Esta por eliminar ${name}`,
      text: "Esta accion no se puede revertir",
      icon: "warning",
      confirmButtonText: "Eliminar Api",
      cancelButtonText: "Cancelar",
      showCancelButton: true
    },() => presenter.removeResource(id))
  }

  const edit= ({id}:CompilerFreePassInterface) => {
      navigate(`/recursos/add/${id}`)
  }
  const handleStatus= ({id, status}:CompilerFreePassInterface) => {
    setIsLoading(true)
    presenter.changeStatus(id,status)
  }
  const handlePageChange= (page:number) => {
    presenter.loadResources(page)
  }
  const dropdownOptions = (object:{[key:string]:any}) => [
    {label: <span className='flex items-center justify-center py-2 text-sm'>Editar<MdEdit className='mx-2'/></span>, action: edit},
    {label: <span className='flex items-center justify-center py-2 text-sm'>Remover<MdDelete className='mx-2'/></span>, action: remove},
    {label: <span className='flex items-center justify-center py-2 text-sm'>Cambiar estado<MdPowerSettingsNew className='mx-2'/></span>, action: handleStatus},
    {label: 
      <CopyToClipboard text={object.authorization_key} onCopy={()=> {
        Alert.fire({
          title:'Clave copiada',
          text: object.authorization_key,
          icon: "success",
        })
      }}>
        <span className='flex items-center justify-center py-2 text-sm'>Autorización<MdCopyAll className='mx-2'/></span>
      </CopyToClipboard>
    }
  ]

  const columns = [
    {
      label: 'Nombre',
      property: 'name',
    },
    {
      label: 'Ruta de contexto',
      property: 'endpoint',
      cell: (data:CompilerFreePassInterface) => ('/' + data)
    },
    {
      label: 'Autor',
      property: 'owner',
      cell: (data:CompilerFreePassInterface) => data.name
    },
    {
      label: 'Estado',
      property: 'status', 
      cell: (data:CompilerFreePassInterface) => (
        data ? <span className='text-success font-medium'>Iniciado</span> : <span className='text-danger font-medium'>Detenido</span>
      )
    },
    {
      label: 'Ruta de contexto',
      property: 'endpoint', 
      cell: (data:CompilerFreePassInterface) => {
        const endpoint = `${URL}/${data}`
        return <CopyToClipboard text={endpoint} onCopy={()=> {
          Alert.fire({
            title:'Ruta de contexto copiada',
            text: endpoint,
            icon: "success",
          })
        }}>
          <span className='flex items-center  hover:text-primary cursor-pointer'><MdLink className='mx-2'/>{endpoint}</span>
        </CopyToClipboard>
      },
    },
    {
      label: 'Acciones',
      center: true,
      cell: (o:CompilerFreePassInterface) => <Dropdown object={o} options={dropdownOptions(o)}/>
    },
  ]

  return (
    <div className='grid-rows-12 grid-cols-12 grid'>
      <div className='w-full col-span-12 flex justify-end'>
        <NavLink to='/recursos/add' className='btn-primary w-fit mx-5'>Añadir recurso</NavLink>
      </div>
      <div className='col-span-12 row-span-11 h-full bg-main overflow-x-auto rounded-lg flex flex-col'>
        <Table data={resources?.items} columns={columns} noDataMessage='No data'/>
      </div>
        <Pagination className='bg-main w-full self-end col-span-12 p-2' onPageChange={handlePageChange} data={resources?.pagination}/>
    </div>
  );
};

export default ResourcesListScreen;