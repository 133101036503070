import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ITestQueryAction {
    execute: (values:any) => Promise<any>;
}

export const TestQueryAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ITestQueryAction => {
    return {
        execute: async (values:any): Promise<any> => {
            return ResourcesAddOrEditGateway.testQuery(values)
        }
    }
}