import { DependencyManager } from "../../dependencyManager";
import { IRequest } from "../../types/http";

import { ResourcesListGateway } from "./infrastructure/gateways/ResourcesListGateway";
import { ResourcesAddOrEditGateway } from "./infrastructure/gateways/ResourcesAddOrEditGateways";
export const resourcesModuleInitilize = (dependencyManager :DependencyManager) => {
    const httpRequest = getHttpRequest(dependencyManager)
    
    const getResourcesListGateway = ResourcesListGateway(httpRequest)
    dependencyManager.register('getResourcesListGateway',getResourcesListGateway, true)

    const getResourcesAddOrEditGateway = ResourcesAddOrEditGateway(httpRequest)
    dependencyManager.register('getResourcesAddOrEditGateway',getResourcesAddOrEditGateway, true)
}

const getHttpRequest = (dependencyManager: DependencyManager) => {
    return dependencyManager.resolve('request') as IRequest
}