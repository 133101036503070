
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md'
interface IPagination {
    marginPagesDisplayed?: number,
    data: any,
    onPageChange?: (page:number) => void,
    className?: string
    
}
const Pagination = ({marginPagesDisplayed=5, data, onPageChange,className} : IPagination) => {
    const { loading=false, total=0, page_count=10, records } = data || {}
    const [activePage, setActivePage] = useState<number>(1)
    const [mounted, setMounted] = useState<boolean>(false)

    const selectPage =  (page: number|string) => {
        if (page === activePage) return
        setActivePage(Number(page))
    }
    const nextPage= () => {
        setActivePage(prevPage => prevPage + 1 )
    }

    const previousPage= () => {
        setActivePage(prevPage => prevPage - 1 )
    }
    
    const numberOfPages = Math.ceil(total / page_count) || 1
    const pages = Array.from(Array(numberOfPages).keys())
    const index = activePage - 1
    const startIndex = index-marginPagesDisplayed >= 0 ? index-marginPagesDisplayed : 0
    const finalIndex = index+marginPagesDisplayed+1
    
    // Showing margin mages only
    let dynamicPages: Array<{name:string|number, value:string|number}> = [...pages.slice(startIndex, finalIndex)].map(e => ({name:e+1, value:e+1}))

    // Handle showing the last activePage always
    if (!(activePage+marginPagesDisplayed >= numberOfPages-1)) {
        dynamicPages = [...dynamicPages,{name:'...', value: activePage + (marginPagesDisplayed + 1 )}, {name:numberOfPages, value:numberOfPages}]
    } 
    else if (!(activePage+marginPagesDisplayed >= numberOfPages)) {
        dynamicPages = [...dynamicPages, {name:numberOfPages, value:numberOfPages}]
    }

    // Handle showing the first activePage always
    if (index-marginPagesDisplayed > 1) {
        dynamicPages = [{name:1, value:1},{name:'...', value: activePage - (marginPagesDisplayed + 1 )}, ...dynamicPages]
    }
    else if (index-marginPagesDisplayed > 0){
        dynamicPages = [{name:1, value:1},...dynamicPages]
    }
    const disablePrev = !numberOfPages || activePage===1
    const disableNext = !numberOfPages || activePage===numberOfPages

    const to = activePage > 1 ? (page_count * (activePage-1) + records ): records
    const from = (activePage === numberOfPages ? to - records : to - page_count) + 1
    useUpdateEffect(()=> {
        setMounted(true)
        if (!mounted) return
        onPageChange && onPageChange(activePage)
    },[activePage])
    return (
        <div className={className}>
            {!loading && <p className="text-center text-xs">Mostrando de {from} a {to} entradas</p>}
            <div className='w-full flex justify-center mt-2'>
                <button disabled={disablePrev} onClick={()=> disablePrev || previousPage()} className={`${disablePrev ? 'text-text-disabled' : 'hover:bg-bg'} py-1 px-2 rounded-lg`}><MdChevronLeft size={20}/></button>
                {dynamicPages?.map(e => {
                        return (
                            <button key={uuidv4()} onClick={()=> selectPage(e.value)} className={`${activePage === e.name ? 'bg-primary hover:bg-primary text-main' : ''}  py-1 px-2 hover:bg-bg rounded-lg mx-1`}>{e.name}</button>
                        )
                    
                })}
                <button disabled={disableNext} onClick={()=> disableNext || nextPage()} className={`${disableNext ? 'text-text-disabled' : 'hover:bg-bg'} py-1 px-2 rounded-lg`}><MdChevronRight size={20}/></button>
             </div>
        </div>
    );
};

export default Pagination;