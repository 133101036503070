import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ILoadResourceToEditAction {
    execute: (id:string) => Promise<any>;
}

export const LoadResourceToEditAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ILoadResourceToEditAction => {
    return {
        execute: async (id:string): Promise<any> => {
            return ResourcesAddOrEditGateway.loadResourceToEdit(id)
        }
    }
}