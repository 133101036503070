import { IResourcesListGateway } from "../gateways/IResourcesListGateway";
import { IResources } from "../types";

export interface ILoadResourcesAction {
    execute: (page_number:number) => Promise<IResources>;
}

export const LoadResourcesAction = (ResourcesListGateway:IResourcesListGateway): ILoadResourcesAction => {
    return {
        execute: async (page_number): Promise<IResources> => {
            return ResourcesListGateway.loadResources(page_number)
        }
    }
}