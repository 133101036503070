import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";

export interface ILoadColumnsAction {
    execute: (values:any) => Promise<any>;
}

export const LoadColumnsAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ILoadColumnsAction => {
    return {
        execute: async (values): Promise<any> => {
            return ResourcesAddOrEditGateway.loadColumns(values)
        }
    }
}