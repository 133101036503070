import { IResourcesAddOrEditGateway } from "../gateways/IResourcesAddOrEditGateway";
export interface ILoadResourceFiltersAction {
    execute: (id:string) => Promise<any>;
}

export const LoadResourceFiltersAction = (ResourcesAddOrEditGateway:IResourcesAddOrEditGateway): ILoadResourceFiltersAction => {
    return {
        execute: async (id:string): Promise<any> => {
            return ResourcesAddOrEditGateway.loadResourceFilters(id)
        }
    }
}