import { IDatasourcesListGateway } from "../gateways/IDatasourcesListGateway";
import { IDatasources } from "../types";
export interface ILoadDatasourcesAction {
    execute: (page_number:number) => Promise<IDatasources>;
}

export const LoadDatasourcesAction = (DatasourcesListGateway:IDatasourcesListGateway): ILoadDatasourcesAction => {
    return {
        execute: async (page_number): Promise<IDatasources> => {
            return DatasourcesListGateway.loadDatasources(page_number)
        }
    }
}