import { ILoadResourcesAction } from '../../core/actions/LoadResourcesAction'
import { IChangeStatusAction } from '../../core/actions/ChangeStatusAction'
import IResourcesListViewHandlers from '../../core/views/IResourcesListViewHandlers'
import { IRemoveResourceAction } from '../../core/actions/RemoveResourceAction'

export const ResourcesListPresenter = (
  viewHandlers: IResourcesListViewHandlers,
  loadResourcesAction: ILoadResourcesAction,
  changeStatusAction: IChangeStatusAction,
  removeResourceAction: IRemoveResourceAction
) => {
  return {
    present() {
      console.log('Lista de recursos renderizada')
    },
    
    loadResources(page_number=1) {
      const loadResourcesRequest = loadResourcesAction.execute(page_number)
      loadResourcesRequest.then((resources) => {
        viewHandlers.loadResourcesOk(resources)
      })
    },
    
    changeStatus(id:string,status:boolean) {
      const changeStatusRequest = changeStatusAction.execute(id,status)
      changeStatusRequest.then(message => viewHandlers.changeStatusOk(message))
    },

    removeResource(id:string) {
      const removeResourceRequest = removeResourceAction.execute(id)
      removeResourceRequest.then(message => viewHandlers.removeResourceOk(message))
    }
  }
}