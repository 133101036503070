import { IDatasourcesAddOrEditGateway } from "../gateways/IDatasourcesAddOrEditGateway";
import { IClients } from "../types";
export interface ILoadClientsAction {
    execute: () => Promise<IClients>;
}

export const LoadClientsAction = (DatasourcesAddOrEditGateway:IDatasourcesAddOrEditGateway): ILoadClientsAction => {
    return {
        execute: async (): Promise<IClients> => {
            return DatasourcesAddOrEditGateway.loadClients()
        }
    }
}